import { getRevisor_an } from '../../services/analytics.services'
import { Analyticschart } from '../../factories/staticGraphicFactory'
import moment from 'moment';
export const MonitorRevisorMixin = {
    data() {
        return {
            loading: true,
            matriculasOpened: {
                linhas: []
            },
            matriculasDownloaded: {},
            matriculasSaved: {
                linhas: []
            },
            ownersAdded: {},
            ownersRemoved: {},
            onusAdded: {},
            onusRemoved: {},
            revisorOpened: {},
            saveError: {},
            saveChart: null,
            onusChart: null,
            ownersChart: null,
            updated: false,
            onusTotal: [],
            ownersTotal: [],
            savedByuser: [],
            getByperiod: {},
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            }
        };
    },
    created() {
        this.getData()
            .then(() => {
                this.mountSavedchart()
                this.mountOnuschart()
                this.mountOwnerschart()
            })
    },
    mounted() {},
    filters: {},
    watch: {},
    computed: {
        totalSaved() {
            return {
                'label': 'Salvas',
                'total': parseInt(this.matriculasSaved.total)
            }
        },
        totalErrorsaved() {
            return {
                'label': 'Erro ao Salvar',
                'total': parseInt(this.saveError.total)
            }
        },
        totalOnusadd() {
            return {
                'label': 'Adicionados',
                'total': this.onusAdded.total
            }
        },
        totalOnusremove() {
            return {
                'label': 'Removidos',
                'total': this.onusRemoved.total
            }
        },
        totalOwnersadd() {
            return {
                'label': 'Adicionados',
                'total': this.ownersAdded.total
            }
        },
        totalOwnersremove() {
            return {
                'label': 'Removidos',
                'total': this.ownersRemoved.total
            }
        },
        totalModelowners() {
            let modelTotal = 0
            if (this.ownersTotal != undefined) {
                this.ownersTotal.forEach((val) => {
                    modelTotal = modelTotal + val.totalModel
                })
                return {
                    'label': 'Model Extract',
                    'total': modelTotal
                }
            } else {
                return {
                    'label': 'Model Extract',
                    'total': 0
                }
            }
        },
        totalModelonus() {
            let modelTotal = 0
            if (this.onusTotal != undefined) {
                this.onusTotal.forEach((val) => {
                    modelTotal = modelTotal + val.totalModel
                })
                return {
                    'label': 'Model Extract',
                    'total': modelTotal
                }
            } else {
                return {
                    'label': 'Model Extract',
                    'total': 0
                }
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            try {
                let { data } = await getRevisor_an({})
                this.treatData(data.data)
                this.loading = false

                this.getAndduplicatefromanalytics(data)

            } catch (e) {
                this.loading = false
                this.$tokentest(e.response.status)
                    //console.log(e)
            }
        },

        treatData(data) {
            let treatedopened = data.opened_matricula.linhas.filter(val => {
                if (val.category.search('v1') === -1) {
                    return val
                }
            })
            data.opened_matricula.linhas = treatedopened
        },
        mountAverbationlist(type) {
            let avAdded = this.$duplicate(this[`${type}Added`].linhas)
            let avRemoved = this.$duplicate(this[`${type}Removed`].linhas)
            let all = this.$duplicate(avAdded.concat(avRemoved))
            all = all.map(val => {
                    return val.label = val.label.split(' ').pop()
                })
                .filter((val, index, object) => {
                    return object.indexOf(val) === index
                })
            let merged = []
            if (avAdded.length > 0) {
                all.filter((val) => {
                    let matricula = val
                    let totalAdded = 0
                    let totalRemoved = 0
                    let total = 0
                    let totalModel = 0
                    avAdded.filter(val_ => {
                        let matriculaID = this.$duplicate(val_.label.split(' ').pop())
                        if (matriculaID === matricula) {
                            totalAdded += parseInt(val_.total)
                            total += parseInt(val_.total)
                            totalModel = parseInt(this.$duplicate(val_.label.split(':').shift()))
                        }
                    })
                    avRemoved.filter(val_ => {
                        let matriculaID = this.$duplicate(val_.label.split(' ').pop())
                        if (matriculaID === matricula) {
                            totalRemoved += parseInt(val_.total)
                            total += parseInt(val_.total)
                            totalModel = parseInt(this.$duplicate(val_.label.split(':').shift()))
                        }
                    })

                    let percentremoved = (totalRemoved * 100) / totalModel
                    merged.push({
                        matricula: matricula,
                        totalAdded: totalAdded,
                        totalRemoved: totalRemoved,
                        total: total,
                        totalModel: totalModel,
                        percentremoved: Math.round(percentremoved)
                    })
                })

                return merged
            }
        },
        getAndduplicatefromanalytics(data) {
            this.matriculasDownloaded = this.$duplicate(data.downloaded)
            this.matriculasOpened = this.$duplicate(data.opened_matricula)
            this.matriculasSaved = this.$duplicate(data.saved)
            this.ownersAdded = this.$duplicate(data.owner_added)
            this.ownersRemoved = this.$duplicate(data.owner_removed)
            this.onusAdded = this.$duplicate(data.onus_add)
            this.onusRemoved = this.$duplicate(data.onus_removed)
            this.errorMatriculas = this.$duplicate(data.error_saved)
            this.revisorOpened = this.$duplicate(data.revisor_opened)
            this.saveError = this.$duplicate(data.error_saved)
            this.onusTotal = this.mountAverbationlist('onus')
            this.ownersTotal = this.mountAverbationlist('owners')
            this.savedByuser = this.mountSavedbyuser()

        },
        mountSavedbyuser() {
            let users = this.$duplicate(this.matriculasSaved.linhas)
            users = users.map((val) => {
                return val.label.split(':').pop()
            }).filter((val, index, object) => {
                return object.indexOf(val) === index
            }).map(val => {
                let filtered = this.$duplicate(this.matriculasSaved.linhas)
                filtered = filtered.filter(filtered => {
                    if (val === filtered.label.split(':').pop()) {
                        return filtered
                    }
                })
                const nome = val
                val = {
                    'nome': nome,
                    'total': filtered.length
                }
                return val
            })
            return users
        },
        mountSavedchart() {
            let data = [this.totalSaved, this.totalErrorsaved]
            this.saveChart = new Analyticschart(data, 'Matrículas Salvas')
        },
        mountOnuschart() {
            let data = [this.totalOnusadd, this.totalOnusremove, this.totalModelonus]
            this.onusChart = new Analyticschart(data, 'Ônus')
        },
        mountOwnerschart() {
            let data = [this.totalOwnersadd, this.totalOwnersremove, this.totalModelowners]
            this.ownersChart = new Analyticschart(data, 'Compradores')
        },
        async getbyPeriod() {
            let dat = {
                'startDate': '',
                'endDate': '',
            }
            dat.startDate = moment(this.dateRange.startDate).locale('en').format('YYYY-MM-DD')
            dat.endDate = moment(this.dateRange.endDate).locale('en').format('YYYY-MM-DD')
            this.loading = true
            try {
                let { data } = await getRevisor_an(dat)
                this.treatData(data.data)
                this.loading = false
                this.getAndduplicatefromanalytics(data)
                this.mountSavedchart()
                this.mountOnuschart()
                this.mountOwnerschart()

            } catch (e) {
                this.loading = false
                this.$tokentest(e.response.status)
                    //console.log(e)
            }
        }
    }
};